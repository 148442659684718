import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./BankList.css";

import config from "../../config/config";
import NavbarSection from "../Navbar/NavbarSection";

const ConnectToBank = ({ userData }) => {
  const navigate = useNavigate();

  // if no token, redirect to login
  if (!localStorage.getItem("token")) {
    navigate("/Login");
  }

  const requisitionLength = localStorage.getItem("Requisition Length");
  const [banks, setBanks] = useState([]);
  const [filteredBanks, setFilteredBanks] = useState([banks]);
  const [selectedBankId, setSelectedBankId] = useState(null);
  const [contryCode, setContryCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [userHasAccounts, setUserHasAccounts] = useState(requisitionLength > 0);

  useEffect(() => {
    const fetchBanks = async () => {
      const results = await fetch(
        `${config.URL_PROD}/api/v1/bank/allBelgiumBanks/${contryCode}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await results.json();
      console.log("Banks data:", data);

      // if data.error === startsWith("Unauthorized") setErrorMessage(data.error);
      if (
        (data.message && data.message?.startsWith("Unauthorized")) ||
        data.message?.startsWith("Failed")
      ) {
        setErrorMessage(data.message);
      }
      // if the data is empty or not an array, set it to an empty array
      if (!Array.isArray(data)) {
        setBanks([]);
        setFilteredBanks([]);
      }
      setBanks(data);
      setFilteredBanks(data || []);
    };
    fetchBanks();
  }, [contryCode]);

  const handleSelectBank = (bankId) => {
    setSelectedBankId(bankId);

    /* console.log("Bank selected", bankId); */

    // start the process of connecting to the bank
    const connectToBank = async () => {
      const results = await fetch(
        `${config.URL_PROD}/api/v1/bank/requisition`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            redirect:
              /* "https://kleinmanshloma-open-banking-server.onrender.com/api/v1/bank/getAccountsAndSaveDatabase", */
              `${config.URL_PROD}/api/v1/bank/getAccountsAndSaveDatabase`,
            institution_id: bankId,
            username: userData.username,
          }),
        }
      );

      // Check if the response is successful
      if (!results.ok) {
        throw new Error(`HTTP error! status: ${results.status}`);
      }

      /* console.log("POST request response:", results); */

      const data = await results.json();
      /* console.log("POST request data:", data); */

      // build the link to redirect to the bank page
      const link = data.link;

      // open on the same page
      window.open(link, "_self");
    };
    connectToBank();
  };

  const searchBank = (e) => {
    const searchTearm = e.target.value;

    const searchValue = searchTearm.toLowerCase();
    const filteredBanks = banks.filter((bank) => {
      return bank.name.toLowerCase().includes(searchValue);
    });

    // set the filtered banks order to search the once that start with the search value first and then the rest
    const sortedBanks = filteredBanks.sort((a, b) => {
      if (a.name.toLowerCase()?.startsWith(searchValue)) {
        return -1;
      } else {
        return 1;
      }
    });

    setFilteredBanks(sortedBanks);

    // when remove the search value, show all the new results sorted by abc
    if (searchValue === "") {
      const sortedBanks = banks.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      setFilteredBanks(sortedBanks);
    }
  };

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage("");
        navigate("/Login");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  if (errorMessage) {
    return (
      <div className="errorMessage">
        {/* add an X to exit earlier */}
        <button
          className="errorCloseBtn"
          onClick={() => {
            setErrorMessage("");
            navigate("/Login");
          }}
        >
          X
        </button>
        <p>{errorMessage}</p>
      </div>
    );
  }

  return (
    <div className="bank-list-page">
      <NavbarSection showCopyUrlSection={false} showConnectionLimits={false} />
      {userHasAccounts && (
        <>
          {/* button to go to accounts */}
          <button
            className="goToAccountsBtn"
            onClick={() => navigate("/bankAccountsData")}
          >
            Go to Accounts
          </button>{" "}
        </>
      )}
      <h1 className="pageName">Connect to Bank</h1>{" "}
      <div className="mainBox">
        <p>Select yor contry</p>

        {/*select yor contry */}
        <select
          className="contry-select"
          name="contry"
          id="contry"
          onChange={(e) => {
            setContryCode(e.target.value);
            /* console.log("Country code:", e.target.value); */
          }}
        >
          <option value="">Select your country</option>
          <option value="be">Belgium</option>
          <option value="gb">United Kingdom</option>
          <option value="nl">Netherlands</option>
          <option value="at">Austria</option>
          <option value="cy">Cyprus</option>
          <option value="de">Germany</option>
        </select>

        {/* search your bank */}
        {contryCode && (
          <>
            <div className="searchBank">
              <div className="searchBank-Title">Search your bank</div>
              <input
                type="text"
                placeholder="Search your bank"
                className="searchBank-searchBox"
                onChange={searchBank}
                autoFocus
              />
            </div>

            <form className="bank-list">
              {Array.isArray(filteredBanks) && filteredBanks.length > 0
                ? filteredBanks.map((bank) => (
                    <div key={bank.id} className="bank-item">
                      <label htmlFor={bank.id}>
                        <div className="bank-details">
                          <input
                            type="radio"
                            id={bank.id}
                            name="bank"
                            value={bank.id}
                            checked={selectedBankId === bank.id}
                            onChange={() => handleSelectBank(bank.id)}
                          />

                          <div className="bank-name-bic">
                            <div className="bank-name">{bank.name}</div>
                            <div className="bank-bic">{bank.bic}</div>
                          </div>

                          <div className="bank-logo-box">
                            <div className="bank-logo">
                              <img src={bank.logo} alt={bank.name} />
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                  ))
                : null}
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default ConnectToBank;
