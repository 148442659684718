import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import CopyWrittingForTheApp from "../CopyWrittingForTheApp/CopyWrittingForTheApp";

import styles from "./Signup.module.css";

import config from "../../config/config";

const Signup = () => {
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    username: "",
    email: "",
    companyName: "",
    password: "",
    confirmPassword: "",
    approvalCode: "",
  });

  const [errorMessages, setErrorMessages] = useState("");

  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const [errorResponse, setErrorResponse] = useState(null);

  const showResponse3Seconds = (duration = 1500) => {
    setTimeout(() => {
      setResponse(null);
      setErrorResponse("");
      if (duration !== 15000) {
        navigate("/Login");
      } else {
        window.location.reload();
      }
    }, duration);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: value,
      };

      if (name === "password" || name === "confirmPassword") {
        if (!updatedData.password || !updatedData.confirmPassword) {
          setErrorMessages("");
        } else if (updatedData.password !== updatedData.confirmPassword) {
          setErrorMessages("Passwords do not match");
        } else {
          setErrorMessages("");
        }
      }

      return updatedData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userData.password !== userData.confirmPassword) {
      setErrorMessages("Passwords do not match");
      return;
    }

    // Email validation regex
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    if (!emailPattern.test(userData.email)) {
      setErrorMessages("Invalid email address");
      return;
    }

    try {
      const response = await fetch(`${config.URL_PROD}/api/v1/auth/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        setErrorResponse(errorResponse.message);
        showResponse3Seconds();
        return;
      }

      const result = await response.json();
      /*  console.log(result); */

      setResponse(result?.message);
      showResponse3Seconds(1500); // 3 seconds
    } catch (error) {
      console.error("Network error:", error);
      setErrorResponse("Network error occurred. Please try again.");
      showResponse3Seconds();
    }
  };

  useEffect(() => {
    /* console.log("User Data: ", userData); */
  }, [userData]);

  return (
    <div className={styles.signupPage}>
      <h1 className={styles.header}>
        <b>Bank Accounts in Excel</b>
      </h1>
      {response && <p className={styles.responseMessage}>{response}</p>}
      {errorResponse && <p className={styles.errorMessage}>{errorResponse}</p>}
      <div className={styles.signupBox}>
        <div className={styles.illustrationWrapper}>
          <CopyWrittingForTheApp />
        </div>
        <form className={styles.signupForm} onSubmit={handleSubmit}>
          <h2 className={styles.title}>Sign Up</h2>
          <div className={styles.formGroup}>
            <label className={styles.label}>Username</label>
            <input
              type="text"
              name="username"
              className={styles.input}
              value={userData.username}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>Email Address</label>
            <input
              type="email"
              name="email"
              className={styles.input}
              value={userData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>Name</label>
            <input
              type="text"
              name="companyName"
              className={styles.input}
              value={userData.companyName}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>Password</label>
            <input
              type="password"
              name="password"
              className={styles.input}
              value={userData.password}
              minLength="8"
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>Confirm Password</label>
            <input
              type="password"
              name="confirmPassword"
              className={styles.input}
              value={userData.confirmPassword}
              onChange={handleChange}
              required
            />
          </div>
          {/* add an Approval code this will approve the user automaticlly while sign up*/}
          <div className={`${styles.approvalCodeContainer}`}>
            <label className={styles.approvalCodeLable}>
              Enter Approval Code (if any)
            </label>
            <input
              type="text"
              name="approvalCode"
              className={styles.approvalCodeInput}
              value={userData.approvalCode}
              onChange={handleChange}
            />
          </div>
          {errorMessages && (
            <div className={styles.errorMessage}>{errorMessages}</div>
          )}
          <div className={styles.signinLink}>
            <NavLink to="/Login">Already signed up?</NavLink>
          </div>
          <button type="submit" className={styles.signupButton}>
            SIGN UP
          </button>
        </form>
      </div>
    </div>
  );
};

export default Signup;
